import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '~/components/ui/card';
import {
  DiscordIcon,
  InstagramIcon,
  TwitchIcon,
  TwitterIcon,
} from '~/components/icons';
import {BanknotesIcon} from '@heroicons/react/24/outline';
import Banner2024 from '~/components/banner-2024';
import {Button} from '~/components/ui/button';
import {CircleStackIcon} from '@heroicons/react/20/solid';
import {Commands} from '~/components/command';
import {EditionCard} from '~/components/edition-card';
import {Gamepad2Icon} from 'lucide-react';
import {Help} from '~/components/help';
import {Intro} from '~/components/header';
import {Link} from 'react-router';
import {Prompt} from '~/components/prompt';
import {Scramble} from '~/components/scramble';
import {Separator} from '~/components/ui/separator';
import {ClientOnly} from 'remix-utils/client-only';
import {Marquee} from '~/components/ui/marquee';

export const headers = () => ({
  'Cache-Control':
    'public, max-age=600, s-maxage=604800, stale-while-revalidate=3154000000',
});

export default function Component() {
  return (
    <>
      <Intro />
      <Banner2024 />

      <Help>
        <p>You can type commands to navigate inside the website:</p>
        <Commands
          commands={{
            games: 'list all games',
            themes: 'list all themes',
            badges: 'list all badges',
            rules: 'show the rules',
            home: 'go back to home page',
            back: 'go back to previous page',
          }}
        />
      </Help>

      <ClientOnly>
        {() => (
          <Link
            to="https://buy.stripe.com/eVa9B44Togk3b7OaEF"
            className="text-accent hover:bg-accent hover:text-background"
          >
            <div className="flex">
              <Marquee
                pauseOnHover
                className="flex text-2xl font-semibold md:text-3xl"
              >
                &nbsp;👕 Pre-order our next T-Shirt now!
              </Marquee>
            </div>
            <Marquee pauseOnHover>
              &nbsp;We'll design a new T-Shirt from the crazy subthemes you give
              us in the checkout form!
            </Marquee>
          </Link>
        )}
      </ClientOnly>

      <div className="grid grid-cols-2 gap-4 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]">
        <EditionCard className="col-span-full" />

        <Card className="col-span-2" variant="primary">
          <CardHeader>
            <CardTitle className="flex items-center gap-4 text-2xl sm:text-3xl">
              <Gamepad2Icon
                className="inline-block size-12"
                strokeWidth={1.5}
              />
              <Scramble>What's that about?</Scramble>
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4 text-lg">
            <CardDescription>
              Get ready for an exhilarating 43-hour{' '}
              <strong>Epic Game Jam</strong>! Dive into the{' '}
              <strong>main theme</strong> and create a game, incorporating as
              many <strong>quirky subthemes</strong> as you can. You can
              participate <strong>online</strong> or enjoy an incredible venue
              in <strong>Neuchâtel, Switzerland</strong>, surrounded by fellow
              video game enthusiasts for an unforgettable weekend. Whether
              you're passionate about game jams, video games, board games, or
              just looking to <strong>create something unique</strong>, this
              event is an awesome opportunity to experience something epic!
            </CardDescription>
            <CardDescription className="text-xl">
              <strong>
                <Scramble>Stay Epic!</Scramble>
              </strong>
            </CardDescription>
          </CardContent>
        </Card>

        <a
          href="https://discord.gg/rdBx3BgsEW"
          className="flex"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Card className="flex flex-col bg-[#5865F2] shadow-3xl active:translate-x-[4px] active:translate-y-[4px] active:shadow-none">
            <CardHeader className="flex grow flex-col justify-between gap-4">
              <DiscordIcon className="size-16" />
              <CardTitle>
                <Scramble>Join our Discord!</Scramble>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                That's where epic stuff happens.
              </CardDescription>
            </CardContent>
          </Card>
        </a>

        <a
          href="https://twitch.tv/epicgamejam"
          className="flex"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Card className="flex flex-col bg-[#9146ff] shadow-3xl active:translate-x-[4px] active:translate-y-[4px] active:shadow-none">
            <CardHeader className="flex grow flex-col justify-between gap-4">
              <TwitchIcon className="size-16" />
              <CardTitle>
                <Scramble>Watch us</Scramble>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We stream the whole event live on Twitch.
              </CardDescription>
            </CardContent>
          </Card>
        </a>

        <a
          href="https://instagram.com/epicgamejam"
          className="flex"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Card className="flex flex-col bg-gradient-to-tl from-[#833ab4] via-[#fd1d1d] to-[#fcb045] shadow-3xl active:translate-x-[4px] active:translate-y-[4px] active:shadow-none">
            <CardHeader className="flex grow flex-col justify-between gap-4">
              <InstagramIcon className="size-16" />
              <CardTitle>
                <Scramble>Look at us</Scramble>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>We post during the epic event.</CardDescription>
            </CardContent>
          </Card>
        </a>

        <a
          href="https://twitter.com/epicgamejam"
          className="flex"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Card className="flex flex-col shadow-3xl active:translate-x-[4px] active:translate-y-[4px] active:shadow-none">
            <CardHeader className="flex grow flex-col justify-between gap-4">
              <TwitterIcon className="size-16" />
              <CardTitle>
                <Scramble>Follow us</Scramble>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>We occasionally tweet.</CardDescription>
            </CardContent>
          </Card>
        </a>

        <a
          href="https://donate.raisenow.io/kspqn"
          className="flex"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Card
            className="flex flex-col shadow-3xl active:translate-x-[4px] active:translate-y-[4px] active:shadow-none"
            variant="accent"
          >
            <CardHeader className="flex grow flex-col justify-between gap-4">
              <BanknotesIcon className="size-16" />
              <CardTitle>
                <Scramble>Support us</Scramble>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We now accept your money to support the event.
              </CardDescription>
            </CardContent>
          </Card>
        </a>
      </div>

      <Separator className="my-8" />

      {/* Sponsors */}
      <div className="mb-8 grid auto-rows-fr grid-cols-[repeat(auto-fill,minmax(150px,1fr))] place-items-center gap-x-6 pr-4">
        {/*<img*/}
        {/*  src="/images/partners/PRH_CD_RGB_white_EN_LOGO.svg"*/}
        {/*  alt="Pro Helvetia"*/}
        {/*  className="w-40 p-4"*/}
        {/*/>*/}
        <img
          src="/images/partners/logo-neuchatel.png"
          alt="Ville de Neuchâtel"
          className="w-40 brightness-0 invert"
        />
        <img
          src="/images/partners/cac-logo-white.svg"
          alt="Case à Chocs"
          className="w-28"
        />
        <img
          src="/images/partners/366_Vnv_logo-512.webp"
          alt="VNV"
          className="w-28 brightness-0 invert"
        />
        <img
          src="/images/partners/Logo_Klakmioch_Black.png"
          alt="Klakmioch"
          className="w-40 brightness-0 invert"
        />
        <img
          src="/images/partners/local-impact-logo.svg"
          alt="Klakmioch"
          className="w-40"
        />
        <img
          src="/images/partners/rotmoos_logo_final.svg"
          alt="Biohof Rotmoos"
          className="w-40"
        />
      </div>

      <div className="mb-12 space-y-6">
        <h2 className="text-3xl">
          <Scramble>You can also support us!</Scramble>
        </h2>
        <div className="prose prose-sm">
          <p>
            <strong>There were some changes</strong> with our finances this
            year. If you want to <strong>support Epic Game Jam</strong>, you're
            more than welcome to donate. We'll use the money to make future
            events even more epic!
          </p>
        </div>
        <Button asChild>
          <Link to="https://donate.raisenow.io/kspqn">
            <CircleStackIcon className="size-4" />
            <span>Donate</span>
            <CircleStackIcon className="size-4" />
          </Link>
        </Button>
      </div>

      <Prompt />
    </>
  );
}
